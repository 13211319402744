var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { staticClass: "text-left", attrs: { fluid: "" } },
    [
      _c(
        "div",
        { staticClass: "filter-bar p-2 mb-3" },
        [
          _c(
            "b-navbar",
            { attrs: { toggleable: "xl" } },
            [
              _c("b-navbar-toggle", { attrs: { target: "filter-collapse" } }),
              _c(
                "b-collapse",
                { attrs: { id: "filter-collapse", "is-nav": "" } },
                [
                  _c(
                    "button",
                    {
                      staticClass: "filter-btn reset",
                      on: {
                        click: function($event) {
                          return _vm.resetFilters()
                        }
                      }
                    },
                    [_vm._v("Reset Filters")]
                  ),
                  _c("b-form-select", {
                    staticClass: "select company",
                    attrs: {
                      options: _vm.companies,
                      "text-field": "name",
                      "value-field": "id",
                      plain: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null, disabled: "" } },
                              [_vm._v("Select Company")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.filters.company_id,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "company_id", $$v)
                      },
                      expression: "filters.company_id"
                    }
                  }),
                  _c("div", { staticClass: "filter-btn-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "filter-btn left",
                        class: _vm.filters.type == "all" ? "selected" : "",
                        on: {
                          click: function($event) {
                            _vm.filters.type = "all"
                          }
                        }
                      },
                      [_vm._v(" All ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "filter-btn right",
                        class: _vm.filters.type == "direct" ? "selected" : "",
                        on: {
                          click: function($event) {
                            _vm.filters.type = "direct"
                          }
                        }
                      },
                      [_vm._v(" Direct ")]
                    )
                  ]),
                  _c("b-form-select", {
                    staticClass: "select",
                    staticStyle: { width: "6%" },
                    attrs: { options: _vm.years, plain: "", required: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null } },
                              [_vm._v("Start Year")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.filters.year,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "year", $$v)
                      },
                      expression: "filters.year"
                    }
                  }),
                  _c("b-form-select", {
                    staticClass: "select",
                    staticStyle: { width: "5%" },
                    attrs: {
                      options: _vm.isComparingIndustry
                        ? _vm.quarters
                        : _vm.periods,
                      plain: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null } },
                              [_vm._v("Start Period")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.filters.startperiod,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "startperiod", $$v)
                      },
                      expression: "filters.startperiod"
                    }
                  }),
                  _c("b-form-select", {
                    staticClass: "select",
                    staticStyle: { width: "5%" },
                    attrs: {
                      options: _vm.isComparingIndustry
                        ? _vm.quarters
                        : _vm.periods,
                      plain: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null } },
                              [_vm._v("End Period")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.filters.endperiod,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "endperiod", $$v)
                      },
                      expression: "filters.endperiod"
                    }
                  }),
                  _c("div", { staticClass: "filter-btn-group" }, [
                    _c(
                      "button",
                      {
                        staticClass: "filter-btn left",
                        class:
                          _vm.filters.comparewho == "Self" ? "selected" : "",
                        on: {
                          click: function($event) {
                            _vm.filters.comparewho = "Self"
                          }
                        }
                      },
                      [_vm._v(" Compare to: Self ")]
                    ),
                    _c(
                      "button",
                      {
                        staticClass: "filter-btn right",
                        class:
                          _vm.filters.comparewho == "Industry"
                            ? "selected"
                            : "",
                        on: {
                          click: function($event) {
                            return _vm.setCompareToIndustry()
                          }
                        }
                      },
                      [_vm._v(" Compare to: Industry ")]
                    )
                  ]),
                  _c("b-form-select", {
                    staticClass: "select",
                    staticStyle: { width: "6%" },
                    attrs: { options: _vm.years, plain: "", required: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null } },
                              [_vm._v("Start Compare Year")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.filters.compareyear,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "compareyear", $$v)
                      },
                      expression: "filters.compareyear"
                    }
                  }),
                  _c("b-form-select", {
                    staticClass: "select",
                    staticStyle: { width: "5%" },
                    attrs: {
                      options: _vm.isComparingIndustry
                        ? _vm.quarters
                        : _vm.periods,
                      plain: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null } },
                              [_vm._v("Start Compare Period")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.filters.comparestartperiod,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "comparestartperiod", $$v)
                      },
                      expression: "filters.comparestartperiod"
                    }
                  }),
                  _c("b-form-select", {
                    staticClass: "select",
                    staticStyle: { width: "5%" },
                    attrs: {
                      options: _vm.isComparingIndustry
                        ? _vm.quarters
                        : _vm.periods,
                      plain: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "first",
                        fn: function() {
                          return [
                            _c(
                              "b-form-select-option",
                              { attrs: { value: null } },
                              [_vm._v("End Compare Period")]
                            )
                          ]
                        },
                        proxy: true
                      }
                    ]),
                    model: {
                      value: _vm.filters.compareendperiod,
                      callback: function($$v) {
                        _vm.$set(_vm.filters, "compareendperiod", $$v)
                      },
                      expression: "filters.compareendperiod"
                    }
                  }),
                  _vm.filters.comparewho == "Industry"
                    ? _c("b-form-select", {
                        staticClass: "select",
                        attrs: { options: _vm.compareBuckets, plain: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [_vm._v("Revenue Bucket")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          4092032074
                        ),
                        model: {
                          value: _vm.filters.comparebucket,
                          callback: function($$v) {
                            _vm.$set(_vm.filters, "comparebucket", $$v)
                          },
                          expression: "filters.comparebucket"
                        }
                      })
                    : _vm._e(),
                  _vm.filters.comparewho == "Self"
                    ? _c("div", { staticClass: "filter-btn-group" }, [
                        _c(
                          "button",
                          {
                            staticClass: "filter-btn left",
                            class:
                              _vm.filters.display == "percent"
                                ? "selected"
                                : "",
                            on: {
                              click: function($event) {
                                _vm.filters.display = "percent"
                              }
                            }
                          },
                          [_vm._v(" % ")]
                        ),
                        _c(
                          "button",
                          {
                            staticClass: "filter-btn right",
                            class:
                              _vm.filters.display == "money" ? "selected" : "",
                            on: {
                              click: function($event) {
                                _vm.filters.display = "money"
                              }
                            }
                          },
                          [_vm._v(" $ ")]
                        )
                      ])
                    : _vm._e(),
                  _c(
                    "b-button",
                    {
                      staticClass: "filter-btn ml-auto",
                      attrs: {
                        disabled: !_vm.filtersAreValid,
                        variant: "primary",
                        size: "sm"
                      },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          return _vm.load.apply(null, arguments)
                        }
                      }
                    },
                    [_vm._v("Load")]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.report && _vm.report.length > 0
        ? _c(
            "div",
            {
              staticStyle: {
                color: "white",
                "font-size": "medium",
                "text-align": "center"
              }
            },
            [
              _c("small", [
                _vm._v("Last Synced: " + _vm._s(_vm.report[0].synced))
              ])
            ]
          )
        : _vm._e(),
      _c(
        "b-container",
        [
          !_vm.filtersChanged
            ? _c(
                "b-overlay",
                { attrs: { show: _vm.loading } },
                [
                  _vm.report && _vm.report.length > 0
                    ? _c(
                        "b-card",
                        { staticClass: "text--black" },
                        [
                          _c(
                            "b-card-title",
                            [
                              _c(
                                "b-row",
                                [
                                  _c("b-col", { staticClass: "text-center" }, [
                                    _vm._v(" " + _vm._s(this.companyname) + " ")
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                {
                                  staticClass: "small mt-4",
                                  attrs: { "no-gutters": "" }
                                },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12", md: "6" } },
                                    [_vm._v("Profit & Loss")]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "text-left small",
                                      class:
                                        _vm.report && _vm.report.length > 1
                                          ? "text-md-left"
                                          : "",
                                      attrs: {
                                        offset: "0",
                                        "offset-md":
                                          _vm.report && _vm.report.length > 1
                                            ? 0
                                            : 3,
                                        cols: "12",
                                        md: "3"
                                      }
                                    },
                                    [
                                      _vm.filters.comparewho === "Industry"
                                        ? _c("div", [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(_vm.filters.startDate) +
                                                  " to "
                                              ),
                                              _vm.filters.endDate !== null
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.filters.endDate
                                                      )
                                                    )
                                                  ])
                                                : _c("span", [
                                                    _vm._v("Current")
                                                  ])
                                            ])
                                          ])
                                        : _c("div", [
                                            _c("strong", [
                                              _vm._v(
                                                _vm._s(_vm.filters.startDate) +
                                                  " to "
                                              ),
                                              _vm.filters.endDate !== null
                                                ? _c("span", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.filters.endDate
                                                      )
                                                    )
                                                  ])
                                                : _c("span", [
                                                    _vm._v("Current")
                                                  ])
                                            ])
                                          ])
                                    ]
                                  ),
                                  _vm.report && _vm.report.length > 1
                                    ? _c(
                                        "b-col",
                                        {
                                          staticClass:
                                            "text-left text-md-left small",
                                          attrs: { cols: "12", md: "3" }
                                        },
                                        [
                                          _c("b", [
                                            _vm.filters.comparewho ===
                                            "Industry"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#BE191E"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      "Industry Average " +
                                                        _vm._s(
                                                          _vm.filters
                                                            .comparestartperiod
                                                        ) +
                                                        " - " +
                                                        _vm._s(
                                                          _vm.filters
                                                            .compareendperiod
                                                        )
                                                    )
                                                  ]
                                                )
                                              : _c(
                                                  "span",
                                                  {
                                                    staticStyle: {
                                                      color: "#BE191E"
                                                    }
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.filters.comparestart
                                                      ) + " to "
                                                    ),
                                                    _vm.filters.compareend !==
                                                    null
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#BE191E"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.filters
                                                                  .compareend
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      : _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              color: "#BE191E"
                                                            }
                                                          },
                                                          [_vm._v("Current")]
                                                        )
                                                  ]
                                                )
                                          ])
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "b-card",
                            [
                              _c("b-card-title", [
                                _vm._v("Ordinary Income/Expense "),
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      float: "right",
                                      "font-size": "medium",
                                      "font-family": "sans-serif"
                                    }
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "padding-right": "25px" }
                                      },
                                      [_vm._v("Actuals")]
                                    ),
                                    _c(
                                      "span",
                                      {
                                        staticStyle: { "padding-right": "55px" }
                                      },
                                      [_vm._v("% of Income")]
                                    ),
                                    _vm.report && _vm.report.length > 1
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "padding-right": "25px",
                                              color: "#BE191E"
                                            }
                                          },
                                          [_vm._v("Compare")]
                                        )
                                      : _vm._e(),
                                    _vm.report && _vm.report.length > 1
                                      ? _c(
                                          "span",
                                          {
                                            staticStyle: {
                                              "padding-right": "75px",
                                              color: "#BE191E"
                                            }
                                          },
                                          [_vm._v("Difference")]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]),
                              _c("AccountSummary", {
                                attrs: {
                                  name: "Income",
                                  accounts: _vm.report[0].accounts.Income,
                                  compared:
                                    _vm.report && _vm.report.length > 1
                                      ? _vm.report[1].accounts.Income
                                      : null,
                                  isComparing: _vm.isComparing,
                                  totalComp:
                                    _vm.report &&
                                    _vm.report.length > 1 &&
                                    _vm.report[1].accounts.length !== 0
                                      ? _vm.report[1].accounts.Income.total
                                      : 0,
                                  filters: _vm.filters,
                                  hidePercent: ""
                                }
                              }),
                              _c("AccountSummary", {
                                attrs: {
                                  name: "Cost of Goods Sold",
                                  accounts:
                                    _vm.report[0].accounts.CostOfGoodsSold,
                                  compared:
                                    _vm.report[1] != null
                                      ? _vm.report[1].accounts.CostOfGoodsSold
                                      : null,
                                  isComparing: _vm.isComparing,
                                  income: _vm.report[0].totalincome,
                                  totalComp:
                                    _vm.report &&
                                    _vm.report.length > 1 &&
                                    _vm.report[1].accounts.length !== 0
                                      ? _vm.report[1].accounts.Income.total
                                      : 0,
                                  filters: _vm.filters
                                }
                              }),
                              _c("TotalDisplay", {
                                attrs: {
                                  label: "Gross Profit",
                                  total: _vm.report[0].gross,
                                  compared:
                                    _vm.report[1] != null
                                      ? _vm.report[1].gross
                                      : null,
                                  isComparing: _vm.isComparing,
                                  income: _vm.report[0].totalincome,
                                  totalComp:
                                    _vm.report &&
                                    _vm.report.length > 1 &&
                                    _vm.report[1].accounts.length !== 0
                                      ? _vm.report[1].accounts.Income.total
                                      : 0,
                                  filters: _vm.filters,
                                  switchComp: ""
                                }
                              }),
                              _c("AccountSummary", {
                                attrs: {
                                  name: "Expenses",
                                  accounts: _vm.report[0].accounts.Expense,
                                  compared:
                                    _vm.report[1] != null
                                      ? _vm.report[1].accounts.Expense
                                      : null,
                                  isComparing: _vm.isComparing,
                                  income: _vm.report[0].totalincome,
                                  totalComp:
                                    _vm.report &&
                                    _vm.report.length > 1 &&
                                    _vm.report[1].accounts.length !== 0
                                      ? _vm.report[1].accounts.Income.total
                                      : 0,
                                  filters: _vm.filters
                                }
                              }),
                              _c("TotalDisplay", {
                                attrs: {
                                  label: "Net Ordinary Income",
                                  total: _vm.report[0].net,
                                  compared:
                                    _vm.report[1] != null
                                      ? _vm.report[1].net
                                      : null,
                                  isComparing: _vm.isComparing,
                                  income: _vm.report[0].totalincome,
                                  totalComp:
                                    _vm.report &&
                                    _vm.report.length > 1 &&
                                    _vm.report[1].accounts.length !== 0
                                      ? _vm.report[1].accounts.Income.total
                                      : 0,
                                  filters: _vm.filters,
                                  switchComp: ""
                                }
                              })
                            ],
                            1
                          ),
                          _c(
                            "b-card",
                            { staticClass: "mt-3" },
                            [
                              _c("b-card-title", [
                                _vm._v("Other Income/Expense")
                              ]),
                              _c("AccountSummary", {
                                attrs: {
                                  name: "Other Income",
                                  accounts: _vm.report[0].accounts.OtherIncome,
                                  compared:
                                    _vm.report[1] != null
                                      ? _vm.report[1].accounts.OtherIncome
                                      : null,
                                  isComparing: _vm.isComparing,
                                  income: _vm.report[0].totalincome,
                                  totalComp:
                                    _vm.report &&
                                    _vm.report.length > 1 &&
                                    _vm.report[1].accounts.length !== 0
                                      ? _vm.report[1].accounts.Income.total
                                      : 0,
                                  filters: _vm.filters
                                }
                              }),
                              _c("AccountSummary", {
                                attrs: {
                                  name: "Other Expenses",
                                  accounts: _vm.report[0].accounts.OtherExpense,
                                  compared:
                                    _vm.report[1] != null
                                      ? _vm.report[1].accounts.OtherExpense
                                      : null,
                                  isComparing: _vm.isComparing,
                                  income: _vm.report[0].totalincome,
                                  totalComp:
                                    _vm.report &&
                                    _vm.report.length > 1 &&
                                    _vm.report[1].accounts.length !== 0
                                      ? _vm.report[1].accounts.Income.total
                                      : 0,
                                  filters: _vm.filters
                                }
                              }),
                              _c("TotalDisplay", {
                                attrs: {
                                  label: "Net Other Income",
                                  total: _vm.report[0].netother,
                                  compared:
                                    _vm.report[1] != null
                                      ? _vm.report[1].netother
                                      : null,
                                  isComparing: _vm.isComparing,
                                  income: _vm.report[0].totalincome,
                                  totalComp:
                                    _vm.report &&
                                    _vm.report.length > 1 &&
                                    _vm.report[1].accounts.length !== 0
                                      ? _vm.report[1].accounts.Income.total
                                      : 0,
                                  filters: _vm.filters
                                }
                              })
                            ],
                            1
                          ),
                          _c("TotalDisplay", {
                            staticClass: "card-body mt-3",
                            attrs: {
                              label: "Net Income",
                              total: _vm.report[0].total,
                              compared:
                                _vm.report[1] != null
                                  ? _vm.report[1].total
                                  : null,
                              isComparing: _vm.isComparing,
                              income: _vm.report[0].totalincome,
                              totalComp:
                                _vm.report &&
                                _vm.report.length > 1 &&
                                _vm.report[1].accounts.length !== 0
                                  ? _vm.report[1].accounts.Income.total
                                  : 0,
                              filters: _vm.filters,
                              switchComp: ""
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }