var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "filter-bar p-2", staticStyle: { "text-align": "start" } },
    [
      _c(
        "b-navbar",
        { attrs: { toggleable: "xl" } },
        [
          _c("b-navbar-toggle", { attrs: { target: "filter-collapse" } }),
          _c("b-collapse", { attrs: { id: "filter-collapse", "is-nav": "" } }, [
            _c(
              "div",
              [
                _c(
                  "div",
                  { staticClass: "d-flex flex-wrap align-items-center" },
                  [
                    _c(
                      "button",
                      {
                        staticClass: "filter-btn reset mr-2 mt-4",
                        on: {
                          click: function($event) {
                            return _vm.reset()
                          }
                        }
                      },
                      [_vm._v("Reset")]
                    ),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0 mr-2",
                        attrs: { label: "Select Company" }
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "select company",
                          staticStyle: { "margin-left": "0 !important" },
                          attrs: {
                            options: _vm.companies,
                            "text-field": "name",
                            "value-field": "id",
                            plain: ""
                          },
                          model: {
                            value: _vm.myFilters.company_id,
                            callback: function($$v) {
                              _vm.$set(_vm.myFilters, "company_id", $$v)
                            },
                            expression: "myFilters.company_id"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0 mr-2",
                        attrs: { label: "Select Year" }
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "select",
                          staticStyle: { "margin-left": "0 !important" },
                          attrs: {
                            options: _vm.years,
                            plain: "",
                            required: ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [_vm._v("Select Year")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.startYear,
                            callback: function($$v) {
                              _vm.startYear = $$v
                            },
                            expression: "startYear"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0 mr-2",
                        attrs: { label: "Start Period" }
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "select",
                          staticStyle: { "margin-left": "0 !important" },
                          attrs: { options: _vm.periods, plain: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [_vm._v("Start Period")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.startPeriod,
                            callback: function($$v) {
                              _vm.startPeriod = $$v
                            },
                            expression: "startPeriod"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0 mr-2",
                        attrs: { label: "End Period" }
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "select",
                          staticStyle: { "margin-left": "0 !important" },
                          attrs: { options: _vm.periods, plain: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [_vm._v("End Period")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.endPeriod,
                            callback: function($$v) {
                              _vm.endPeriod = $$v
                            },
                            expression: "endPeriod"
                          }
                        })
                      ],
                      1
                    ),
                    _c("div", { staticClass: "filter-btn-group mt-4" }, [
                      _c(
                        "button",
                        {
                          staticClass: "filter-btn left",
                          class:
                            _vm.myFilters.period == "month" ? "selected" : "",
                          on: {
                            click: function($event) {
                              _vm.myFilters.period = "month"
                            }
                          }
                        },
                        [_vm._v(" Month ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "filter-btn right",
                          class:
                            _vm.myFilters.period == "quarter" ? "selected" : "",
                          on: {
                            click: function($event) {
                              _vm.myFilters.period = "quarter"
                            }
                          }
                        },
                        [_vm._v(" Quarter ")]
                      )
                    ]),
                    _c("div", { staticClass: "filter-btn-group mt-4" }, [
                      _c(
                        "button",
                        {
                          staticClass: "filter-btn left",
                          class: _vm.myFilters.type == "all" ? "selected" : "",
                          on: {
                            click: function($event) {
                              _vm.myFilters.type = "all"
                            }
                          }
                        },
                        [_vm._v(" All ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "filter-btn right",
                          class:
                            _vm.myFilters.type == "direct" ? "selected" : "",
                          on: {
                            click: function($event) {
                              _vm.myFilters.type = "direct"
                            }
                          }
                        },
                        [_vm._v(" Direct ")]
                      )
                    ]),
                    _c("div", { staticClass: "filter-btn-group mt-4 mr-2" }, [
                      _c(
                        "button",
                        {
                          staticClass: "filter-btn left",
                          class:
                            _vm.myFilters.display == "percent"
                              ? "selected"
                              : "",
                          on: {
                            click: function($event) {
                              _vm.myFilters.display = "percent"
                            }
                          }
                        },
                        [_vm._v(" % ")]
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "filter-btn right",
                          class:
                            _vm.myFilters.display == "money" ? "selected" : "",
                          on: {
                            click: function($event) {
                              _vm.myFilters.display = "money"
                            }
                          }
                        },
                        [_vm._v(" $ ")]
                      )
                    ]),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0 mr-2",
                        attrs: { label: "Compare Year" }
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "select",
                          staticStyle: { "margin-left": "0 !important" },
                          attrs: {
                            options: _vm.years,
                            plain: "",
                            required: ""
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [_vm._v("Compare Year")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.compareYear,
                            callback: function($$v) {
                              _vm.compareYear = $$v
                            },
                            expression: "compareYear"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0 mr-2",
                        attrs: { label: "Compare Start" }
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "select",
                          staticStyle: { "margin-left": "0 !important" },
                          attrs: { options: _vm.periods, plain: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [_vm._v("Compare Start")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.compareStartPeriod,
                            callback: function($$v) {
                              _vm.compareStartPeriod = $$v
                            },
                            expression: "compareStartPeriod"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "b-form-group",
                      {
                        staticClass: "mb-0 mr-2",
                        attrs: { label: "Compare End" }
                      },
                      [
                        _c("b-form-select", {
                          staticClass: "select",
                          staticStyle: { "margin-left": "0 !important" },
                          attrs: { options: _vm.periods, plain: "" },
                          scopedSlots: _vm._u([
                            {
                              key: "first",
                              fn: function() {
                                return [
                                  _c(
                                    "b-form-select-option",
                                    { attrs: { value: null } },
                                    [_vm._v("Compare End Period")]
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ]),
                          model: {
                            value: _vm.compareEndPeriod,
                            callback: function($$v) {
                              _vm.compareEndPeriod = $$v
                            },
                            expression: "compareEndPeriod"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "settings-cog-box" },
                      [
                        _c("b-icon-gear-wide-connected", {
                          directives: [
                            {
                              name: "b-modal",
                              rawName: "v-b-modal",
                              value: "addWidgets",
                              expression: "'addWidgets'"
                            }
                          ],
                          staticClass: "h3 my-auto"
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "b-modal",
                  {
                    attrs: {
                      id: "addWidgets",
                      title: "Add Widgets",
                      "no-close-on-backdrop": "",
                      "no-close-on-esc": "",
                      "ok-title": "Save"
                    },
                    on: {
                      ok: _vm.handleSave,
                      show: function($event) {
                        _vm.widgetsToAdd = []
                      },
                      hidden: function($event) {
                        _vm.widgetsToAdd = []
                      }
                    }
                  },
                  [
                    _c("report-selector", {
                      attrs: { widgets: _vm.widgets },
                      on: { update: _vm.newWidgets }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }